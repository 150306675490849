@import '../references.scss';

.ant-picker {
    background-color: $fieldBG !important;
    padding: 14px 20px !important;
    border: none !important;
    border-radius: 10px !important;
    .ant-picker-input {
        > input::placeholder {
            color: $dark4 !important;
            font-weight: 400 !important;
            font-size: 16px !important;
        }
    }

    &.ant-picker-status-error {
        border: 1.5px solid $red !important;
    }

    &.ant-picker-range {
        overflow: hidden !important;
    }
}
