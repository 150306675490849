@import '../references.scss';

.ant-tree {
    background-color: $fieldBG !important;
    border-radius: 10px !important;
    padding: 16px !important;

    .ant-tree-list {
        .ant-tree-list-holder {
            .ant-tree-list-holder-inner {
                .ant-tree-treenode {
                    width: 100% !important;
                    // display: flex !important;
                    // align-items: center !important;
                    // flex-direction: row-reverse !important;
                    // justify-content: space-between !important;
                    border-bottom: 1.5px solid rgba($color: $dark5, $alpha: 0.25) !important;
                    margin-top: 8px;
                    padding-bottom: 16px;
                    > span:last-child {
                        width: 100% !important;
                    }

                    &.ant-tree-treenode-switcher-close {
                        .ant-tree-node-content-wrapper-close {
                            .ant-tree-title {
                                color: $dark2;
                                font-weight: 500 !important;
                                font-size: 16px !important;
                            }
                        }
                    }
                    &.ant-tree-treenode-switcher-open {
                        .ant-tree-node-content-wrapper-open {
                            .ant-tree-title {
                                color: $dark1;
                                font-weight: 500 !important;
                                font-size: 16px !important;
                            }
                        }
                    }

                    &.ant-tree-treenode-selected {
                        .ant-tree-node-selected {
                            background-color: transparent !important;
                            .ant-tree-title {
                                color: $primary !important;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 8px;
                                &::after {
                                    content: 'X';
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 12px;
                                    width: 10px;
                                    height: 10px;
                                    padding: 4px 6px 0 6px;
                                    // border-radius: 100%;
                                    // background-color: $primary;
                                }
                            }
                        }
                    }

                    .ant-tree-node-content-wrapper-normal {
                        .ant-tree-title {
                            font-size: 16px !important;
                            font-weight: 400 !important;

                            &.ant-tree-node-selected {
                                .ant-tree-title {
                                    color: $primary !important;
                                }
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: none !important;
                    }
                }

                .ant-tree-switcher {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                    * {
                        transition: all 0.3s ease;
                    }
                }
                .ant-tree-treenode-switcher-open {
                    .ant-tree-switcher_open {
                        > svg {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }
}
