@import '../references.scss';

.ant-select {
    &:not(.ant-select-status-error) {
        .ant-select-selector {
            border: none !important;
            box-shadow: none !important;
        }
    }

    .ant-select-selector {
        padding: 0 20px !important;
    }
}

.ant-select-dropdown {
    .ant-select-item-option-disabled {
        cursor: default !important;
    }
}
