@import './display';
@import '../references.scss';

html {
    font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-size: 24px;
    color: $dark1;
}

a {
    font-weight: 400;
    color: $dark1;
    font-size: 16px;
}

p {
    font-weight: 400;
    color: $dark2;
    font-size: 16px;
}

//------ font size ------//

$typo-p-sizes: (
    16px: 1rem,
    14px: 0.875rem,
    12px: 0.75rem,
);

$typo-h-sizes: (
    34px: 2.125rem,
    27px: 1.688rem,
    24px: 1.5rem,
    18px: 1.125rem,
    16px: 1rem,
    14px: 0.875rem,
);

@each $px, $rem in $typo-p-sizes {
    $i: index($typo-p-sizes, $px $rem);
    .t-p#{$i} {
        font-size: $px;
        font-size: $rem;
    }
    .t-p#{$i}-i {
        font-size: $px !important;
        font-size: $rem !important;
    }
}

// pre-md H tag sizes
@each $px, $rem in $typo-h-sizes {
    $i: index($typo-h-sizes, $px $rem);
    .t-h#{$i} {
        font-size: $px;
        font-size: $rem;
    }
    .t-h#{$i}-i {
        font-size: $px !important;
        font-size: $rem !important;
    }
}

// md H tag sizes
@each $px, $rem in $typo-h-sizes {
    $i: index($typo-h-sizes, $px $rem);
    .t-md-h#{$i} {
        @include app-bp(md) {
            font-size: $px;
            font-size: $rem;
        }
    }
    .t-md-h#{$i}-i {
        @include app-bp(md) {
            font-size: $px !important;
            font-size: $rem !important;
        }
    }
}

//------ line height ------//

$typo-line-heights: (54.4px, 42.74px, 37.99px, 28.21px, 26px, 24px, auto);

@each $l in $typo-line-heights {
    $i: index($typo-line-heights, $l);
    .t-l#{$i} {
        line-height: $l;
    }
    .t-l#{$i}-i {
        line-height: $l !important;
    }
}

//------ font weight ------//

$typo-weights: (900, 800, 700, 600, 500, 400, 300);

@each $w in $typo-weights {
    .t-#{$w} {
        font-weight: $w;
    }
    .t-#{$w}-i {
        font-weight: $w !important;
    }
}

//------ color ------//

$typo-colors: (
    white: white,
    primary: $primary,
    blue2: $blue2,
    blue3: $blue3,
    dark1: $dark1,
    dark2: $dark2,
    dark3: $dark3,
    dark4: $dark4,
    dark5: $dark5,
    fieldBG: $fieldBG,
    green: $green,
    red: $red,
    darkGreen: $darkGreen,
    yellow: $yellow,
);

@each $key, $value in $typo-colors {
    .t-#{$key} {
        color: $value;
    }
    .t-#{$key}-i {
        color: $value !important;
    }
}
