html {
    &[lang='fa'] {
        * {
            font-family: AnjomanMax;
            -moz-font-feature-settings: 'ss05';
            -webkit-font-feature-settings: 'ss05';
            font-feature-settings: 'ss05';
        }
    }

    &[lang='en'] {
        * {
            font-family: AnjomanMax;
        }
    }
    &[dir='ltr'] {
        direction: ltr;
        text-align: left;
    }

    &[dir='rtl'] {
        direction: rtl;
        text-align: right;
    }
}

.ltr-i {
    direction: ltr !important;
}
.ltr {
    direction: ltr;
}
.rtl-i {
    direction: rtl !important;
}
.rtl {
    direction: rtl;
}
