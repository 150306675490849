.ant-drawer-content {
    .ant-drawer-body {
        padding: 0 !important;
    }

    .ant-drawer-header {
        .ant-drawer-header-title {
            justify-content: flex-end;
        }
    }
}
