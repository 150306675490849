@import './references/breakpoints';
@import './references/colors';
@import './references/direction';

$contentMaxWidth: 1160px;
$sideMargin: 20px;
$headerHeight: (100px, 80px); // (desktop, mobile)
$collapseHeaderHeight: 86px; // desktop
$footerHeight: (248px, 432px); // (desktop, mobile)
$bottomNavigation: 70px;
