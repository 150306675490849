@import '../references.scss';

.ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
        > button > span {
            color: $primary !important;
        }
    }
}

.ant-pagination-item:not(.ant-pagination-item-active) {
    margin: 8px !important;
    backdrop-filter: blur(16px) !important;
}
.ant-pagination-item-active {
    border: none !important;
}
