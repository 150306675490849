@import '../references.scss';
.best-brands-collapse {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 20px 20px 0 0 !important;
    margin-bottom: 12px !important;
    &.best-brands {
        margin-bottom: 8px !important;
        // border-radius: 0 !important;
        // .ant-collapse-item {
        //     border-radius: 0 !important;

        //     .ant-collapse-header {
        //         border-radius: 0 !important;
        //     }
        // }

        // &:first-child {
        //     border-radius: 20px 20px 0 0 !important;
        // }
        // &:last-child {
        //     border-radius: 0 0 20px 20px !important;
        // }
    }
    .ant-collapse-item {
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 20px 20px 0 0 !important;
        .ant-collapse-header {
            margin: 0 !important;
            padding: 16px 24px !important;
            @include app-pre-bp(md) {
                padding: 16px 12px !important;
            }
            border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
            border-radius: 20px !important;
        }

        .ant-collapse-content-active,
        .ant-collapse-content {
            .ant-collapse-content-box {
                border-radius: 0 0 20px 20px;
                background-color: #fff;
            }
        }
    }
}
