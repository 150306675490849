@import '../references.scss';

.event-detail-form {
    input:disabled,
    textarea:disabled {
        color: $dark2 !important;
        cursor: default !important;
    }

    .ant-select-disabled {
        .ant-select-selector {
            color: $dark2 !important;
            cursor: default !important;
        }
    }

    .ant-picker-disabled,
    .ant-upload-disabled {
        color: $dark2 !important;
        cursor: default !important;
    }
}
