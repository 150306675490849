@import '../references.scss';

.ant-btn {
    transition: 0.4s !important;

    &.ant-btn-default {
        background-color: $primary !important;
        color: white !important;
        border: none !important;
        outline: none !important;

        &:hover:not(:disabled) {
            background-color: #0088c2 !important;
        }

        &:disabled {
            color: $dark4 !important;
            background-color: rgba($dark5, 0.25) !important;
        }
    }

    &.ant-btn-primary {
        background-color: white !important;
        color: $dark2 !important;
        border: 1px solid rgba($dark5, 0.25) !important;
        outline: none !important;

        &:hover:not(:disabled) {
            background-color: rgba($dark5, 0.25) !important;
            border: 1px solid rgba($dark5, 0) !important;
        }

        &:active:not(:disabled) {
            background-color: rgba($dark5, 0.25) !important;
            border: 1px solid rgba($dark5, 0) !important;
        }

        &:disabled {
            color: $dark4 !important;
        }
    }
}
