@import '../references.scss';
.ant-input-group-wrapper {
    .ant-input-wrapper {
        .ant-input-group-addon {
            background-color: $fieldBG !important;

            > button {
                &.ant-btn.ant-btn-default {
                    background: $fieldBG !important;
                }

                > span {
                    color: $dark3 !important;
                }
            }
        }
    }
}
