@import '../references.scss';

.ant-slider {
    .ant-slider-track {
        background-color: $primary !important;
    }

    .ant-slider-handle {
        &::after {
            width: 11px !important;
            height: 11px !important;
            background-color: $primary !important;
            border-color: $primary !important;
            box-shadow: none !important;
        }
    }
}

.ant-tooltip {
    .ant-tooltip-arrow {
        display: none !important;
    }
    .ant-tooltip-content {
        .ant-tooltip-inner {
            padding: 0 !important;
            background-color: transparent !important;
            color: $primary !important;
            box-shadow: none !important;
            position: absolute !important;
            top: -23px !important;
            left: -21px !important;
            font-size: 16px !important;
            font-weight: 400 !important;
        }
    }
}
