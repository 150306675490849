@import '../references.scss';

a {
    outline: none;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

image {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    min-height: 100vh;
    @include app-bp-md(padding-bottom, 0, $bottomNavigation);

    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}
