@mixin rtl {
    @at-root body[dir='rtl'] & {
        @content;
    }
}

@mixin ltr {
    @at-root body[dir='ltr'] & {
        @content;
    }
}
