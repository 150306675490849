@import '../references.scss';

.ant-table-wrapper {
    border: 1.5px solid $dark6;
    border-radius: 20px !important;
    overflow: hidden !important;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

    .ant-spin-nested-loading {
        .ant-spin-container {
            .ant-table {
                .ant-table-container {
                    .ant-table-content {
                        > table {
                            .ant-table-thead {
                                > tr {
                                    > th {
                                        color: $dark3 !important;
                                        background-color: rgba(248, 249, 250, 0.5) !important;
                                        font-weight: 400 !important;

                                        &::before {
                                            display: none;
                                        }
                                    }

                                    > th:last-child {
                                        @include app-pre-bp(md) {
                                            text-align: end;
                                        }
                                    }
                                }
                            }

                            .ant-table-tbody {
                                .ant-table-row {
                                    .ant-table-cell {
                                        .upload-pending {
                                            width: fit-content !important;
                                            color: $yellow !important;
                                            padding: 4px 8px !important;
                                            border-radius: 10px;
                                            background-color: #fef7e5 !important;
                                        }
                                        .rejected {
                                            width: fit-content !important;
                                            color: $red !important;
                                            padding: 4px 8px !important;
                                            border-radius: 10px;
                                            background-color: #feeceb !important;
                                        }
                                        .pending {
                                            width: fit-content !important;
                                            color: #009de0 !important;
                                            padding: 4px 8px !important;
                                            border-radius: 10px;
                                            background-color: #e5f5fc !important;
                                        }
                                        .success {
                                            width: fit-content !important;
                                            color: $darkGreen !important;
                                            padding: 4px 8px !important;
                                            border-radius: 10px;
                                            background-color: #e5f7ed !important;
                                        }

                                        @include app-pre-bp(md) {
                                            .service-tile-md,
                                            .details-md {
                                                display: flex;
                                                flex-direction: column;
                                            }
                                            .details-md {
                                                align-items: flex-end;
                                            }
                                            .service-tile-md {
                                                align-items: flex-start;
                                            }

                                            .service-tile-md > p:first-child,
                                            .details-md > a:first-child {
                                                margin-bottom: 12px;
                                            }
                                            .details-md > p {
                                                text-align: end;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
