@import '../references.scss';

.ant-collapse {
    background-color: $fieldBG !important;
    border: none !important;
    padding: 16px !important;
    border-radius: 10px !important;

    .ant-collapse-item {
        border-color: $dark6 !important;
        border-bottom: 1.5px solid $dark6 !important;

        &:last-child {
            border: none !important;
        }

        .ant-collapse-content {
            background-color: $fieldBG !important;
            border-top: 1.5px solid $dark6 !important;
        }

        .ant-collapse-header {
            padding: 16px 0 !important;
            .ant-collapse-header-text {
                color: $dark2;
                font-size: 16px;
                font-weight: 500;
            }
            .ant-collapse-expand-icon {
                > svg {
                    color: $dark4;
                    transition: 0.2s;
                }
            }
        }

        &.ant-collapse-item-active {
            .ant-collapse-content-active {
                border-top: 1.5px solid $dark6 !important;
            }
            .ant-collapse-header {
                .ant-collapse-header-text {
                    color: $dark1;
                    font-size: 16px;
                    font-weight: 500;
                }
                .ant-collapse-expand-icon {
                    > svg {
                        transform: rotate(-90deg);
                        color: $dark1;
                    }
                }
            }
        }
    }
}
