@import '../references.scss';

$spacingPX: 0px, 8px, 16px, 24px, 32px, 40px, 48px, 56px, 64px, 72px, 80px;
$spacingREM: 0rem, 0.5rem, 1rem, 1.5rem, 2rem, 2.5rem, 3rem, 3.5rem, 4rem, 4.5rem, 5rem;
$spacingDirection: (
    '': '',
    't': '-top',
    'l': '-left',
    'r': '-right',
    'b': '-bottom',
);

$spacingProperties: (
    'p': padding,
    'm': margin,
);

@for $i from 1 through length($spacingPX) {
    @each $propertySign, $property in $spacingProperties {
        .#{$propertySign}v-#{$i - 1} {
            #{$property}-top: #{nth($spacingPX, $i)};
            #{$property}-top: #{nth($spacingREM, $i)};
            #{$property}-bottom: #{nth($spacingPX, $i)};
            #{$property}-bottom: #{nth($spacingREM, $i)};
        }
        .#{$propertySign}h-#{$i - 1} {
            #{$property}-left: #{nth($spacingPX, $i)};
            #{$property}-left: #{nth($spacingREM, $i)};
            #{$property}-right: #{nth($spacingPX, $i)};
            #{$property}-right: #{nth($spacingREM, $i)};
        }
        .#{$propertySign}v-#{$i - 1}-i {
            #{$property}-top: #{nth($spacingPX, $i)} !important;
            #{$property}-top: #{nth($spacingREM, $i)} !important;
            #{$property}-bottom: #{nth($spacingPX, $i)} !important;
            #{$property}-bottom: #{nth($spacingREM, $i)} !important;
        }
        .#{$propertySign}h-#{$i - 1}-i {
            #{$property}-left: #{nth($spacingPX, $i)} !important;
            #{$property}-left: #{nth($spacingREM, $i)} !important;
            #{$property}-right: #{nth($spacingPX, $i)} !important;
            #{$property}-right: #{nth($spacingREM, $i)} !important;
        }
        .#{$propertySign}s-#{$i - 1} {
            @include rtl {
                #{$property}-right: #{nth($spacingPX, $i)};
                #{$property}-right: #{nth($spacingREM, $i)};
            }
            @include ltr {
                #{$property}-left: #{nth($spacingPX, $i)};
                #{$property}-left: #{nth($spacingREM, $i)};
            }
        }
        .#{$propertySign}e-#{$i - 1} {
            @include rtl {
                #{$property}-left: #{nth($spacingPX, $i)};
                #{$property}-left: #{nth($spacingREM, $i)};
            }
            @include ltr {
                #{$property}-right: #{nth($spacingPX, $i)};
                #{$property}-right: #{nth($spacingREM, $i)};
            }
        }
        .#{$propertySign}s-#{$i - 1}-i {
            @include rtl {
                #{$property}-right: #{nth($spacingPX, $i)} !important;
                #{$property}-right: #{nth($spacingREM, $i)} !important;
            }
            @include ltr {
                #{$property}-left: #{nth($spacingPX, $i)} !important;
                #{$property}-left: #{nth($spacingREM, $i)} !important;
            }
        }
        .#{$propertySign}e-#{$i - 1}-i {
            @include rtl {
                #{$property}-left: #{nth($spacingPX, $i)} !important;
                #{$property}-left: #{nth($spacingREM, $i)} !important;
            }
            @include ltr {
                #{$property}-right: #{nth($spacingPX, $i)} !important;
                #{$property}-right: #{nth($spacingREM, $i)} !important;
            }
        }
        @each $directionSign, $direction in $spacingDirection {
            .#{$propertySign}#{$directionSign}-#{$i - 1} {
                #{$property}#{$direction}: #{nth($spacingPX, $i)};
                #{$property}#{$direction}: #{nth($spacingREM, $i)};
            }
            .#{$propertySign}#{$directionSign}-#{$i - 1}-i {
                #{$property}#{$direction}: #{nth($spacingPX, $i)} !important;
                #{$property}#{$direction}: #{nth($spacingREM, $i)} !important;
            }
        }
    }
}
