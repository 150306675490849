@import '../references.scss';
.collapse-info {
    background-color: #fff !important;
    padding: 0 !important;
    .ant-collapse-item {
        margin-bottom: 12px !important;
        .ant-collapse-header {
            padding: 0 !important;
        }

        .ant-collapse-content {
            background-color: #fff !important;
            border: none !important;
            &.ant-collapse-content-box,
            &.ant-collapse-content-active {
                border: none !important;
            }
            .ant-collapse-content-box {
                padding: 12px 0 0 0 !important;
                .ant-checkbox-group {
                    display: flex !important;
                    flex-direction: column !important;
                    gap: 8px !important;
                }
            }
        }
    }
}
