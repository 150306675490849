@import '../references.scss';
.yt-campaign {
    &.ant-steps {
        @include app-pre-bp(md) {
            max-width: fit-content !important;
            gap: 8px !important;
        }
        gap: 12px !important;
        .ant-steps-item {
            &:first-child {
                .ant-steps-item-container {
                    @include rtl {
                        margin-right: 16px;
                    }
                    @include ltr {
                        margin-left: 16px;
                    }
                }
            }
            &:not(.ant-steps-item-finish) {
                @include app-pre-bp(md) {
                    padding: 8px;
                }
                padding: 8px 16px;
                border-radius: 40px;
                background-color: #fff;
                border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
            }
            max-width: fit-content !important;
            .ant-steps-item-content {
                display: none !important;
            }
            .ant-steps-item-icon {
                margin: 0 !important;
                // margin-right: 16px !important;
            }
            .ant-steps-icon {
                > p {
                    color: $dark3 !important;
                }
            }
            &.ant-steps-item-finish {
                padding: 0 !important;
                .ant-steps-item-icon {
                    min-width: 41px !important;
                    max-width: 41px !important;
                    height: 41px !important;
                    border-radius: 100% !important;
                    background-color: $primary !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center;
                    svg {
                        color: #fff !important;
                    }
                }
            }
            &.ant-steps-item-active {
                @include app-pre-bp(md) {
                    padding: 8px;
                }
                padding: 8px 16px;
                border-radius: 40px;
                background-color: $primary;
                .ant-steps-icon {
                    > p {
                        color: #ffffff !important;
                    }
                }
            }
        }
    }
}
